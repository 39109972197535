.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
  transition: background-color 0.3s, color 0.3s;
}

.timer {
  font-size: 6rem;
  font-weight: bold;
  margin-bottom: 2rem;
  width: 100%;
  max-width: 300px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.controls {
  display: flex;
  gap: 1rem;
  margin-bottom: 4rem;  /* Doubled from 2rem to 4rem */
}

.toggle-switch-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 30px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f4f4f4;
  transition: .4s;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
}

.toggle-label {
  margin-left: 10px;
  cursor: pointer;
  font-size: 0.9rem;
}

/* Dark mode styles */
.dark {
  background-color: #1a1a1a;
  color: #ffffff;
}

.light {
  background-color: #ffffff;
  color: #1a1a1a;
}

button {
  margin: 5px;
  padding: 10px;
  font-size: 16px;
  width: 100px;  /* Set a fixed width for all buttons */
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
  margin-left: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 30px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f4f4f4;
  transition: .4s;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
  z-index: 2;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

input:checked + .slider {
  background-color: #2c3e50;
}

input:checked + .slider:before {
  transform: translateX(30px);
}

.slider .emoji {
  z-index: 1;
  font-size: 18px;
}

.toggle-label {
  margin-left: 10px;
  cursor: pointer;
}

.App.dark {
  background-color: #1a1a1a;
  color: #ffffff;
}

.App.light {
  background-color: #ffffff;
  color: #1a1a1a;
}

.timer-input {
  font-size: 6rem;
  font-weight: bold;
  width: 100%;
  max-width: 300px;
  height: 120px;
  text-align: center;
  background: none;
  border: none;
  color: inherit;
  font-family: inherit;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
}

.timer-input:focus {
  outline: none;
}

input {
  -webkit-tap-highlight-color: transparent;
}

input:focus {
  outline: none;
}

* {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

input[type="text"],
input[type="text"]:focus,
input[type="text"]:active {
  -webkit-tap-highlight-color: transparent !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

input[type="text"]:focus {
  outline: none !important;
}

.task-manager input[type="text"] {
  width: 100%;
  padding: 8px 0;
  font-size: 1rem;
  border: none;
  border-bottom: 1px solid #555;
  background-color: transparent;
  color: inherit;
  transition: border-color 0.3s, color 0.3s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.task-manager.light input[type="text"] {
  border-bottom-color: rgba(0, 0, 0, 0.1);
}

.task-manager.light input[type="text"]:focus {
  border-bottom-color: #1a1a1a;
}

.task-manager input[type="text"]::placeholder {
  color: #555;
}

.App.light .timer-input:focus {
  outline: none;
}